// tslint:disable-next-line
// export const API_URL = window.API_URL || 'https://api.vuiapp.nanofin.tech'
// export const WSS_URL = window.WSS_URL || 'wss://api.vuiapp.nanofin.tech'
// export const KEYCLOAK_URL =
//   window.KEYCLOAK_URL || 'https://auth.vuiapp.nanofin.tech/auth'

export const API_URL = window.API_URL || 'https://api.sandbox.vuiapp.vn'
export const WSS_URL = window.WSS_URL || 'wss://api.vuiapp.vn'
export const KEYCLOAK_URL =
  window.KEYCLOAK_URL || 'https://auth.sandbox.vuiapp.vn/auth'
